import { messagesConstants } from './messages.constants';
import { messagesActions } from './messages.actions';

import _ from 'lodash';

import { IMessage } from 'sections/CHAT/components/CHATMessangeChat/components/Message/Message';

export interface IConversation {
  mesages: IMessage[];
  concernId: string;
  concernTitle: string;
  id: string;
  lastMessage: string;
  newMessageCount: number;
  offerId: null | string;
  recipientFirstname: string;
  recipientId: string;
  recipientSurname: string;
}

export interface IActiveChatData {
  recipientFirstname: string;
  recipientSurname: string;
  totalRecords: number;
  messages: IMessage[];
}

const INITIAL_STATE = {
  conversations: [] as IConversation[],
  activeChatData: {
    recipientFirstname: '',
    recipientSurname: '',
    messages: [],
    totalRecords: 0,
  } as IActiveChatData,
  isNewNotReceivedMessage: false,
  activeChatId: '',
  yourActiveChatPartnerId: '',
  yourId: '',
  isChatStarted: false,
  isChatConnectionLost: false,
};

export interface IMessagesReducer {
  conversations: IConversation[];
  activeChatData: IActiveChatData | null;
  isNewNotReceivedMessage: boolean;
  activeChatId: string;
  yourActiveChatPartnerId: string;
  yourId?: string;
  isChatStarted: boolean;
  isChatConnectionLost: boolean;
}

function messagesReducer(state = INITIAL_STATE, action: messagesActions) {
  switch (action.type) {
    case messagesConstants.SET_YOUR_ID:
      return {
        ...state,
        yourId: action.yourId,
      };

    case messagesConstants.CLEAR_YOUR_ID:
      return {
        ...state,
        yourId: '',
      };

    case messagesConstants.SET_ACTIVE_CHAT_DATA:
      return {
        ...state,
        activeChatData: action.activeChatData,
      };

    case messagesConstants.SET_INITIAL_CONVERSATION:
      const newState = _.cloneDeep(state);
      const isAlreadyExisting = state.conversations.filter(
        (item: IConversation) => item.id === action.conversations?.id,
      );

      if (isAlreadyExisting.length)
        return {
          ...state,
        };

      const newConversation: IConversation = {
        id: action.conversations?.id!,
        mesages: [],
        newMessageCount: action.conversations?.newMessageCount!,
        concernId: action.conversations?.concernId!,
        concernTitle: action.conversations?.concernTitle!,
        lastMessage: action.conversations?.lastMessage!,
        offerId: action.conversations?.offerId!,
        recipientFirstname: action.conversations?.recipientFirstname!,
        recipientId: action.conversations?.recipientId!,
        recipientSurname: action.conversations?.recipientSurname!,
      };

      if (newConversation.newMessageCount) {
        newState.isNewNotReceivedMessage = true;
      }

      newState.conversations.push(newConversation);

      return newState;

    case messagesConstants.LOAD_MORE_CONVERSATIONS:
      const stateToReturn = _.cloneDeep(state);

      let theSameConversations = action.conversationsToRemove!;

      for (let i = 0; i < action.loadedMoreConversations!.length; i++) {
        if (i >= theSameConversations) {
          const newLoadedConversation: IConversation = {
            // @ts-ignore
            ...action.loadedMoreConversations[i],
            mesages: [],
          };

          stateToReturn.conversations.push(newLoadedConversation);
        }
      }

      return stateToReturn;

    case messagesConstants.ADD_MESSAGE_TO_CHAT:
      const st = _.cloneDeep(state);

      if (action.conversationId === state.activeChatId) {
        st.activeChatData.messages.push(action.message!);
      }

      return st;

    case messagesConstants.SET_NEW_CONVS_ORDER:
      const isNewMsgFromConvsOnList = !!state.conversations.filter(
        (item: IConversation) =>
          item.id === action.newReceivedMessage!.conversationId,
      ).length;

      if (isNewMsgFromConvsOnList) {
        const isConvFirstOnConsList =
          state.conversations[0].id ===
          action.newReceivedMessage!.conversationId;

        if (isConvFirstOnConsList) {
          return {
            ...state,
          };
        } else {
          const convToMove = state.conversations.filter(
            (item: IConversation) =>
              item.id === action.newReceivedMessage!.conversationId,
          )[0];

          const convsWithoutConvFromNewMsg = state.conversations.filter(
            (item: IConversation) =>
              item.id !== action.newReceivedMessage!.conversationId,
          );

          convsWithoutConvFromNewMsg.unshift(convToMove);

          return {
            ...state,
            conversations: convsWithoutConvFromNewMsg,
          };
        }
      } else {
        const isStartedByYou =
          state.yourId === action.newReceivedMessage?.senderId;

        const newConv: IConversation = {
          concernId: '',
          concernTitle: action.newReceivedMessage?.concernTitle!,
          id: action.newReceivedMessage!.conversationId!,
          lastMessage: action.newReceivedMessage?.content!,
          mesages: [],
          newMessageCount: 0,
          offerId: '',
          recipientFirstname: isStartedByYou
            ? state.activeChatData.recipientFirstname
            : action.newReceivedMessage?.firstname!,
          recipientId: isStartedByYou
            ? state.yourActiveChatPartnerId
            : action.newReceivedMessage?.senderId!,
          recipientSurname: isStartedByYou
            ? state.activeChatData.recipientSurname
            : action.newReceivedMessage?.surname!,
        };

        const newConvs = _.cloneDeep(state.conversations);

        newConvs.unshift(newConv);

        return {
          ...state,
          conversations: newConvs,
        };
      }

    case messagesConstants.RESET_CONVERSATION_NEW_MESSAGES_COUNT:
      const newChangedState = _.cloneDeep(state);
      const newAllMessagesa = newChangedState.conversations;
      const idnexOfConversationToModifya = newAllMessagesa.findIndex(
        (item: IConversation) => item.id === action.conversationId,
      );
      newAllMessagesa[idnexOfConversationToModifya].newMessageCount = 0;

      let isNoLongerAnyMessageNotReceived: string[] = [];

      newChangedState.conversations.forEach((item: IConversation) => {
        if (item.newMessageCount > 0) {
          isNoLongerAnyMessageNotReceived.push(item.id);
        }
      });

      if (!isNoLongerAnyMessageNotReceived.length) {
        newChangedState.isNewNotReceivedMessage = false;
      }

      return newChangedState;

    case messagesConstants.INCREASE_CONVERSATION_NEW_MESSAGES_COUNT:
      if (state.yourId === action.senderId)
        return {
          ...state,
        };
      const newChangedStatea = _.cloneDeep(state);
      const newAllMessagesad = newChangedStatea.conversations;
      const idnexOfConversationToModifyas = newAllMessagesad.findIndex(
        (item: IConversation) => item.id === action.conversationId,
      );
      newAllMessagesad[idnexOfConversationToModifyas].newMessageCount =
        newAllMessagesad[idnexOfConversationToModifyas].newMessageCount + 1;
      newChangedStatea.isNewNotReceivedMessage = true;
      return newChangedStatea;

    case messagesConstants.SET_LAST_MESSAGE:
      const newStatea = _.cloneDeep(state);
      const idnexOfConversationToM = newStatea.conversations.findIndex(
        (item: IConversation) => item.id === action.conversationId,
      );

      newStatea.conversations[
        idnexOfConversationToM
      ].lastMessage = action.lastMessage!;

      return newStatea;

    case messagesConstants.SET_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeChatId: action.conversationId!,
      };

    case messagesConstants.SET_YOUR_ACTIVE_CHAT_PARTNER_ID:
      return {
        ...state,
        yourActiveChatPartnerId: action.activeChatUserId!,
      };

    case messagesConstants.CLEAR_CHAT_DATA:
      return {
        conversations: [],
        isNewNotReceivedMessage: false,
        activeChatId: '',
        yourActiveChatPartnerId: '',
      };

    case messagesConstants.CLEAR_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeChatId: '',
      };

    case messagesConstants.CLEAR_ACTIVE_CHAT_DATA:
      return {
        ...state,
        activeChatData: null,
      };

    case messagesConstants.CLEAR_YOUR_ACTIVE_CHAT_PARTNER_ID:
      return {
        ...state,
        yourActiveChatPartnerId: '',
      };

    case messagesConstants.START_CHAT:
      return {
        ...state,
        isChatStarted: true,
      };

    case messagesConstants.STOP_CHAT:
      return {
        ...state,
        isChatStarted: false,
      };

    case messagesConstants.CHAT_CONNECTION_STATE:
      return {
        ...state,
        isChatConnectionLost: true,
      };

    case messagesConstants.LOAD_MORE_MESSAGES:
      const stateBeforeAddNewMessages = state;

      // const index = stateBeforeAddNewMessages.conversations.findIndex(
      //   (item: IConversation) => item.id === action.conversationId,
      // );

      let theSameMessages = action.messagesToRemove!;

      for (let i = action.messages?.length!; i > 0; i--) {
        if (theSameMessages <= 0) {
          // @ts-ignore
          const messageToAdd = action.messages[i - 1];

          stateBeforeAddNewMessages.activeChatData.messages.unshift(
            messageToAdd,
          );
          // stateBeforeAddNewMessages.conversations[index].mesages.unshift(
          //   messageToAdd,
          // );
        }

        theSameMessages--;
      }

      return stateBeforeAddNewMessages;

    default:
      return state;
  }
}

export default messagesReducer;
