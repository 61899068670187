import { combineReducers } from 'redux';

import clientOpinionsReducer, {
  IClientOpinios,
} from './clientOpinions/clientOpinions.reducer';
import userDataReducer, { IUserDataState } from './userData/userData.reducer';

import userQuestionsReducer, {
  IClientQuestions,
} from './clientQestion/clientQestion.reducer';
import formDropDownsReducer, {
  IFormDropDowns,
} from './FormDropDowns/FormDropDowns.reducer';
import ClientConcernDataReducer, {
  IClientConcernTable,
} from 'store/ClientConcerns/ClientConcerns.reducer';

import messagesReducer, {
  IMessagesReducer,
} from 'store/messages/messages.reducer';
import offersToClientConcernReducer, {
  IlawyerOffersToClientConcernReducer,
} from './OffersToClientConcern/OffersToClientConcern.reducer';

const rootReducer = combineReducers({
  clientsOpinions: clientOpinionsReducer,
  usetData: userDataReducer,
  userQuestions: userQuestionsReducer,
  dropDownsItems: formDropDownsReducer,
  ClientConcernData: ClientConcernDataReducer,
  messages: messagesReducer,
  offersToClientConcern: offersToClientConcernReducer,
});

export default rootReducer;

export interface RootState {
  clientsOpinions: IClientOpinios;
  usetData: IUserDataState;
  userQuestions: IClientQuestions;
  dropDownsItems: IFormDropDowns;
  ClientConcernData: IClientConcernTable;
  messages: IMessagesReducer;
  offersToClientConcern: IlawyerOffersToClientConcernReducer;
}
