import { clientsOpinionsConstants } from './clientOpinions.constants';
import { clientOpinionsAcions } from './clientOpinions.actions';

export interface IClientOpinios {
  activeItem: string;
}

const INITIAL_STATE = {
  activeItem: '' as string,
};

function clientOpinionsReducer(
  state = INITIAL_STATE,
  action: clientOpinionsAcions,
) {
  switch (action.type) {
    case clientsOpinionsConstants.ADD_CLIENT_OPINION:
      const newActiveItema = action.isActiveByDefault
        ? action.itemName
        : state.activeItem;
      return {
        ...state,
        activeItem: newActiveItema,
      };

    case clientsOpinionsConstants.SET_ACTIVE_OPINION_NAME:
      const newActiveItem = action.itemName;
      return {
        ...state,
        activeItem: newActiveItem,
      };

    default:
      return state;
  }
}

export default clientOpinionsReducer;
