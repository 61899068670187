import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { QuestionsTableItemStatus } from '../QuestionsTableItem/QuestionsTableItem';
export const StyledQuestionsTableItem = styled.tr`
  min-height: 90px;

  border-bottom: 1px solid #e6e6e6;
  border-collapse: collapse;
`;

interface TableItemParProps {
  wordBreak?: boolean;
}

export const StyledQuestionTableItemPart = styled.td<TableItemParProps>`
  padding: 23px 20px;
  word-break: ${({ wordBreak }) => (wordBreak ? 'break-word' : 'normal')};
  font-size: 14px;
  line-height: 17px;
  color: rgba(26, 26, 26, 0.8);
  border-collapse: collapse;
`;

interface StyledQuestionTableIteTextProps {
  status: QuestionsTableItemStatus;
}

const getColor = css<StyledQuestionTableIteTextProps>`
  color: ${({ status }) => {
    switch (status) {
      case QuestionsTableItemStatus.ACTIVE:
        return '#137171';
      case QuestionsTableItemStatus.FINISHED:
        return '#387ad6';
      case QuestionsTableItemStatus.ACCEPTED:
        return '#FFAD32';
      default:
        return '#FFAD32';
    }
  }};
`;
export const StyledQuestionTableIteText = styled.span<StyledQuestionTableIteTextProps>`
  ${getColor}
  font-weight: 700;
`;

export const StyledTableOffersLink = styled(Link)`
  color: #1a1a1a;
  opacity: 0.9;
  text-decoration: none;
  font-size: 14px;
  line-height: 17px;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledTableOffersDimLink = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: rgba(26, 26, 26, 0.8);
  opacity: 0.4;
`;

export const StyledTableEditIcons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface StyledTableEditIconProps {
  isDisabledLayout?: boolean;
  marginRight?: number;
}

export const StyledTableEditIcon = styled.button<StyledTableEditIconProps>`
  cursor: pointer;
  border: none;
  background: none;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  opacity: ${({ isDisabledLayout }) => (isDisabledLayout ? 0.5 : 1)};
`;

export const StyledMarkAsDoneBtn = styled.button`
  background-color: #0f6262;
  padding: 6px 12px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #ffffff;
  cursor: pointer;
`;
