import {
  StyledQuestionsTableItem,
  StyledQuestionTableItemPart,
  StyledQuestionTableIteText,
  StyledTableOffersDimLink,
  StyledTableOffersLink,
  StyledTableEditIcons,
  StyledTableEditIcon,
  StyledMarkAsDoneBtn,
} from './QuestionsTableItem.styled';
import { ReactComponent as EditPencil } from 'assets/images/tablesIcons/editPencil.svg';
import { ReactComponent as DeleteTrash } from 'assets/images/tablesIcons/deleteTrash.svg';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router';

export enum QuestionsTableItemStatus {
  NONE = 'None',
  FINISHED = 'Finished',
  ACTIVE = 'Active',
  ACCEPTED = 'Accepted',
}
export interface QuestionsTableItemProps {
  canEdit?: boolean;
  id: string;
  readableId: string;
  createdAt: string;
  title: string;
  offersCount: number;
  messagesCount: number;
  status: QuestionsTableItemStatus;
  clientId?: string;
  firstName?: string;
  lastName?: string;
  categoryName?: string;
  toggleModal?: (concernId: string) => void;
  toggleMarkAsDownModal: (concernId: string) => void;
}

const QuestionsTableItem: React.FC<QuestionsTableItemProps> = ({
  canEdit,
  readableId,
  createdAt,
  title,
  offersCount,
  messagesCount,
  status,
  id,
  toggleModal,
  toggleMarkAsDownModal,
}) => {
  const history = useHistory();
  const statusToDisplay = () => {
    switch (status) {
      case QuestionsTableItemStatus.ACTIVE:
        return 'Aktywna';
      case QuestionsTableItemStatus.FINISHED:
        return 'Zakończona';
      case QuestionsTableItemStatus.ACCEPTED:
        return 'Zaakceptowane';
      default:
        return 'Aktywna';
    }
  };

  const handleToggleDeleteModal = () => {
    if (toggleModal) {
      toggleModal(id);
    }
  };
  const handleToggleMarkAsDownModal = () => {
    toggleMarkAsDownModal(id);
  };

  const handleEditConcern = () => {
    history.push(`/account/offer/${id}/edit`);
  };

  const isAccepted = status === 'Accepted';

  const offersLink = offersCount ? (
    <StyledTableOffersLink to={`/account/offer/${id}`}>
      Zobacz oferty
    </StyledTableOffersLink>
  ) : (
    <StyledTableOffersDimLink>Zobacz oferty</StyledTableOffersDimLink>
  );

  return (
    <StyledQuestionsTableItem>
      <StyledQuestionTableItemPart>{readableId}</StyledQuestionTableItemPart>
      <StyledQuestionTableItemPart>
        {createdAt.slice(0, 10)}
      </StyledQuestionTableItemPart>
      <StyledQuestionTableItemPart wordBreak>
        {title}
      </StyledQuestionTableItemPart>
      <StyledQuestionTableItemPart>
        {`${offersCount}`}
      </StyledQuestionTableItemPart>
      <StyledQuestionTableItemPart>{`${messagesCount}`}</StyledQuestionTableItemPart>

      <StyledQuestionTableItemPart>
        <StyledQuestionTableIteText status={status}>
          {statusToDisplay()}
        </StyledQuestionTableIteText>
      </StyledQuestionTableItemPart>

      <StyledQuestionTableItemPart>{offersLink}</StyledQuestionTableItemPart>

      <StyledQuestionTableItemPart>
        <StyledTableEditIcons>
          {isAccepted ? (
            <StyledMarkAsDoneBtn onClick={handleToggleMarkAsDownModal}>
              Zakończ
            </StyledMarkAsDoneBtn>
          ) : (
            <>
              <StyledTableEditIcon
                data-tip
                data-for={canEdit ? 'edit-enabled' : 'edit-disabled'}
                onClick={canEdit ? handleEditConcern : undefined}
                // disabled={!canEdit}
                isDisabledLayout={!canEdit}
                marginRight={15}
              >
                <EditPencil />
              </StyledTableEditIcon>

              <ReactTooltip
                id="edit-enabled"
                aria-haspopup="true"
                disable={!!canEdit}
              >
                <p>Edytuj</p>
              </ReactTooltip>

              <ReactTooltip
                id="edit-disabled"
                aria-haspopup="true"
                disable={!!canEdit}
              >
                <p>
                  Nie można edytować zapytania, ponieważ istnieją powiazane z
                  nim oferty bądź wiadomości.
                </p>
              </ReactTooltip>

              <StyledTableEditIcon
                data-tip
                data-for={canEdit ? 'remove-enabled' : 'remove-disabled'}
                onClick={canEdit ? handleToggleDeleteModal : undefined}
                isDisabledLayout={!canEdit}
              >
                <DeleteTrash />
              </StyledTableEditIcon>
              <ReactTooltip id="remove-enabled" aria-haspopup="true">
                <p>Usuń</p>
              </ReactTooltip>
              <ReactTooltip id="remove-disabled" aria-haspopup="true">
                <p>
                  Nie można usunąć zapytania, ponieważ istnieją powiazane z nim
                  oferty bądź wiadomości.
                </p>
              </ReactTooltip>
            </>
          )}
        </StyledTableEditIcons>
      </StyledQuestionTableItemPart>
    </StyledQuestionsTableItem>
  );
};

export default QuestionsTableItem;
