import { messagesConstants } from './messages.constants';
// import { IMessage } from './messages.reducer';
import { IMessage } from 'sections/CHAT/components/CHATMessangeChat/components/Message/Message';
import { IUsersConversations } from 'sections/CHAT/CHAT';
import { Dispatch } from 'redux';
import { tokensHelper } from 'helpers/tokensHelper';
import { axiosInstance } from 'helpers/axiosInstance';
import { API } from 'api/API';
import { chatService } from 'services/chatService';
import { IActiveChatData } from '../messages/messages.reducer';

export interface messagesActions {
  newReceivedMessage?: IMessage;
  activeChatData?: IActiveChatData;
  tempConversation?: IUsersConversations;
  messagesToRemove?: number;
  activeChatUserId?: string;
  lastMessage?: string;
  conversations?: IUsersConversations;
  conversationsToRemove?: number;
  loadedMoreConversations?: IUsersConversations[];
  messages?: IMessage[];
  message?: IMessage;
  conversationId?: string;
  yourId?: string;
  senderId?: string;
  type: messagesConstants;
  firstName?: string;
  lastName?: string;
}

export const setYourId = (yourId: string): messagesActions => ({
  yourId,
  type: messagesConstants.SET_YOUR_ID,
});

export const clearYourId = (): messagesActions => ({
  type: messagesConstants.CLEAR_YOUR_ID,
});

export const setActiveChatData = (
  activeChatData: IActiveChatData,
): messagesActions => ({
  activeChatData,
  type: messagesConstants.SET_ACTIVE_CHAT_DATA,
});

export const clearActiveChatData = (): messagesActions => ({
  type: messagesConstants.CLEAR_ACTIVE_CHAT_DATA,
});

export const setInitialConversations = (
  conversations: IUsersConversations,
): messagesActions => ({
  conversations,
  type: messagesConstants.SET_INITIAL_CONVERSATION,
});

export const loadMoreConversations = (
  conversationsToRemove: number,
  loadedMoreConversations: IUsersConversations[],
): messagesActions => ({
  conversationsToRemove,
  loadedMoreConversations,
  type: messagesConstants.LOAD_MORE_CONVERSATIONS,
});

export const addNewMessage = (message: IMessage, conversationId: string) => ({
  message,
  conversationId,
  type: messagesConstants.ADD_MESSAGE_TO_CHAT,
});

export const resetConversationNewMessagesCount = (
  conversationId: string,
): messagesActions => ({
  conversationId,
  type: messagesConstants.RESET_CONVERSATION_NEW_MESSAGES_COUNT,
});

export const clearActiveChatId = (): messagesActions => ({
  type: messagesConstants.CLEAR_ACTIVE_CHAT_ID,
});

export const increaseConvNewMessagesCount = (
  conversationId: string,
  senderId: string,
): messagesActions => ({
  conversationId,
  senderId,
  type: messagesConstants.INCREASE_CONVERSATION_NEW_MESSAGES_COUNT,
});

export const setNewConvsOrder = (newReceivedMessage: IMessage) => ({
  newReceivedMessage,
  type: messagesConstants.SET_NEW_CONVS_ORDER,
});

export const clearYourActiveChatPartnerId = (): messagesActions => ({
  type: messagesConstants.CLEAR_YOUR_ACTIVE_CHAT_PARTNER_ID,
});

export const setLastMessage = (
  conversationId: string,
  lastMessage: string,
): messagesActions => ({
  conversationId,
  lastMessage,
  type: messagesConstants.SET_LAST_MESSAGE,
});

export const setActiveChatId = (conversationId: string): messagesActions => ({
  conversationId,
  type: messagesConstants.SET_ACTIVE_CHAT_ID,
});

export const setYourActiveChatPartnerId = (
  activeChatUserId: string,
): messagesActions => ({
  activeChatUserId,
  type: messagesConstants.SET_YOUR_ACTIVE_CHAT_PARTNER_ID,
});

export const clearChatData = (): messagesActions => ({
  type: messagesConstants.CLEAR_CHAT_DATA,
});

export const loadMoreMessages = (
  // conversationId: string,
  messagesToRemove: number,
  messages: IMessage[],
): messagesActions => ({
  // conversationId,
  messagesToRemove,
  messages,
  type: messagesConstants.LOAD_MORE_MESSAGES,
});

export const startChat = () => ({
  type: messagesConstants.START_CHAT,
});

export const stopChat = () => ({
  type: messagesConstants.STOP_CHAT,
});

export const getLastTenMessengers = () => async (dispatch: Dispatch) => {
  try {
    const currentAccessToken = tokensHelper.getAccessToken();

    const res = await axiosInstance.get(`${API.url}/api/chat/Conversation`, {
      headers: {
        Authorization: `Bearer ${currentAccessToken}`,
      },
    });

    res.data.data.forEach((item: IUsersConversations) => {
      dispatch(setInitialConversations(item));
    });
  } catch (err) {}
};

export const getLastChatConversations = (activeConcernId: string) => async (
  dispatch: Dispatch,
) => {
  const currentAccessToken = localStorage.getItem('accessToken');

  const res = await axiosInstance.get(
    `${API.url}/api/chat/Conversation?Page=1&PageSize=10`,
    {
      headers: {
        Authorization: `Bearer ${currentAccessToken}`,
      },
    },
  );
  const conversationToShow: IUsersConversations = res.data.data.filter(
    (item: IUsersConversations) => item.id === activeConcernId,
  )[0];

  dispatch(setActiveChatId(conversationToShow.id));
  dispatch(setYourActiveChatPartnerId(conversationToShow.recipientId));
};

export const getChatConversationId = (
  concernId: string,
  offerId?: string,
  recipientId?: string,
) => async (dispatch: Dispatch) => {
  const accessToken = tokensHelper.getAccessToken();

  const res = await axiosInstance.post(
    `${API.url}/api/chat/Conversation`,
    {
      concernId,
      offerId,
      recipientId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  return res;
};

export const fetchMoreMessages = (
  chatId: string,
  pageToFetch: number,
  pageSize: number,
  msgWithTheSameKey: number,
) => async (dispatch: Dispatch) => {
  const currentAccessToken = localStorage.getItem('accessToken');
  const res = await axiosInstance.get(
    `${API.url}/api/chat/Conversation/${chatId}/messages?Page=${pageToFetch}&PageSize=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${currentAccessToken}`,
      },
    },
  );

  dispatch(loadMoreMessages(msgWithTheSameKey, res.data.data));
};

export const sendReadNewMessagesNotify = (
  activeConversationId: string,
) => async (dispatch: Dispatch) => {
  await chatService.notifyAboutReadMessage(activeConversationId);

  dispatch(resetConversationNewMessagesCount(activeConversationId));
};

export const sendMessage = (value: string, activeChatId: string) => async (
  dispatch: Dispatch,
) => {
  const currentAccessToken = tokensHelper.getAccessToken();

  try {
    await axiosInstance.post(
      `${API.url}/api/chat/Conversation/${activeChatId}/messages`,
      {
        content: value,
      },
      {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
      },
    );
  } catch (err) {}
};

export const fetchMoreConversations = (
  pageToFetch: number,
  pageSize: number,
  msgsWithTheSameKey: number,
) => async (dispatch: Dispatch) => {
  const currentAccessToken = localStorage.getItem('accessToken');
  const res = await axiosInstance.get(
    `${API.url}/api/chat/Conversation/?Page=${pageToFetch}&PageSize=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${currentAccessToken}`,
      },
    },
  );

  dispatch(loadMoreConversations(msgsWithTheSameKey, res.data.data));
};

export const setChatConnectionState = () => ({
  type: messagesConstants.CHAT_CONNECTION_STATE,
});

export const fetchCurrentChatData = (activeChatId: string) => async (
  dispatch: Dispatch,
) => {
  const currentAccessToken = localStorage.getItem('accessToken');

  try {
    const res = await axiosInstance.get(
      `${API.url}/api/chat/Conversation/${activeChatId}/messages`,
      {
        headers: {
          Authorization: `Bearer ${currentAccessToken}`,
        },
      },
    );

    const activeChatData: IActiveChatData = {
      recipientFirstname: res.data.recipientFirstname,
      recipientSurname: res.data.recipientSurname,
      messages: res.data.data,
      totalRecords: res.data.totalRecords,
    };
    dispatch(setActiveChatData(activeChatData));
  } catch (err) {}
};
