import { FormDropDownsConstants } from './FormDropDowns.constants';
import { userDataActionType } from './FormDropDowns.actions';
import _ from 'lodash';

export interface IDropdownItem {
  id: string;
  value: string;
}

export interface IFactors {
  key: string;
  value: string;
}

export interface IFormDropDowns {
  provinces: IDropdownItem[];
  ocupations: IDropdownItem[];
  categories: IDropdownItem[];
  factors: IFactors[];
}

const INITIAL_STATE = {
  provinces: [] as IDropdownItem[],
  ocupations: [] as IDropdownItem[],
  categories: [] as IDropdownItem[],
  factors: [] as IFactors[],
};

function formDropDownsReducer(
  state = INITIAL_STATE,
  action: userDataActionType,
) {
  switch (action.type) {
    case FormDropDownsConstants.SET_PROVINCES:
      const originalState = _.cloneDeep(state);
      originalState.provinces = action.items!;

      return originalState;

    case FormDropDownsConstants.SET_OCUPATIONS:
      const originalStateForProffesions = _.cloneDeep(state);
      originalStateForProffesions.ocupations = action.items!;
      return originalStateForProffesions;

    case FormDropDownsConstants.SET_CATEGORIES:
      return {
        ...state,
        categories: action.items,
      };

    case FormDropDownsConstants.SET_FACTORS:
      return {
        ...state,
        factors: action.factors,
      };

    case FormDropDownsConstants.CLEAR_ALL_DROP_DOWNS_ITEMS:
      return {
        ...state,
        provinces: [],
        ocupations: [],
        categories: [],
        factors: [],
      };

    default:
      return state;
  }
}

export default formDropDownsReducer;
