export enum clientsOpinionsConstants {
  SET_USER_DATA = 'SET_USER_DATA',
  CLEAR_USER_DATA = 'CLEAR_USER_DATA',
  SET_LAWYER_PROFFESIONS = 'SET_LAWYER_PROFFESIONS',
  SET_LAWYER_PROVINCE = 'SET_LAWYER_PROVINCE',
  LOG_IN_GET_REQUEST = 'LOG_IN_GET_REQUEST',
  LOG_IN_GET_SUCCESS = 'LOG_IN_GET_SUCCESS',
  LOG_IN_GET_FAILURE = 'LOG_IN_GET_FAILURE',
  CLOSE_WARN_BAR = 'CLOSE_WARN_BAR',
  SET_LAWYER_REGISTER_INFO = 'SET_LAWYER_REGISTER_INFO',
  CLEAR_LAWYER_REGISTER_INFO = 'CLEAR_LAWYER_REGISTER_INFO',
  SET_RESET_PASSWD_INFO = 'SET_RESET_PASSWD_INFO',
  CLEAR_RESET_PASSWD_INFO = 'CLEAR_RESET_PASSWD_INFO',
}
