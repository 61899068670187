import { Route, Redirect } from 'react-router-dom';

export enum UserRoles {
  User = 'User',
  Lawyer = 'Lawyer',
}

export interface PrivateRouteProps {
  path: string;
  redirectPath?: string;
  exact: boolean;
  avaliableOnlyFor?: UserRoles;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  exact,
  children,
  redirectPath,
  avaliableOnlyFor,
}) => {
  const isUserLogged =
    !!localStorage.getItem('accessToken') &&
    !!localStorage.getItem('refreshToken');
  const userRole = localStorage.getItem('userRole');

  return (
    <>
      <Route path={path} exact={exact}>
        {avaliableOnlyFor ? (
          avaliableOnlyFor === userRole && isUserLogged ? (
            children
          ) : (
            <Redirect to={redirectPath ? redirectPath : '/login'} />
          )
        ) : isUserLogged ? (
          children
        ) : (
          <Redirect to={redirectPath ? redirectPath : '/login'} />
        )}
      </Route>
    </>
  );
};

export default PrivateRoute;
