import { clientsOpinionsConstants } from './userData.constants';
import { IUserData } from './userData.reducer';
import { Dispatch } from 'redux';
import { axiosInstance } from 'helpers/axiosInstance';
import { API } from 'api/API';
import { tokensHelper } from 'helpers/tokensHelper';
import { IUserDataToSent } from 'sections/Account/components/AccountSettings/components/AccountSettingsForm/AccountSettingsForm';
import { setYourId } from 'store/messages/messages.actions';

interface registerValues {
  firstName: string;
  surname: string;
  email: string;
  password: string;
  repeatedPassword: string;
  provinceId: string;
  city?: string;
  phoneNumber?: string | number;
  concernId?: string;
  companyName?: string;
  occupationId?: string;
}

export interface userDataActionType {
  yourId?: string;
  tokenRefreshingInterval?: number;
  errorMessage?: string;
  userData?: IUserData;
  type: clientsOpinionsConstants;
}

export const setRegisterLawyerInfo = () => ({
  type: clientsOpinionsConstants.SET_LAWYER_REGISTER_INFO,
});
export const clearRegisterLawyerInfo = () => ({
  type: clientsOpinionsConstants.CLEAR_LAWYER_REGISTER_INFO,
});

export const setResetPasswdInfo = () => ({
  type: clientsOpinionsConstants.SET_RESET_PASSWD_INFO,
});
export const clearResetPasswdInfo = () => ({
  type: clientsOpinionsConstants.CLEAR_RESET_PASSWD_INFO,
});

export const setUserData = (userData: IUserData): userDataActionType => ({
  userData,
  type: clientsOpinionsConstants.SET_USER_DATA,
});

export const clearUserData = (): userDataActionType => ({
  type: clientsOpinionsConstants.CLEAR_USER_DATA,
});

export const loginGetRequest = (): userDataActionType => ({
  type: clientsOpinionsConstants.LOG_IN_GET_REQUEST,
});

export const loginGetSuccess = (): userDataActionType => ({
  type: clientsOpinionsConstants.LOG_IN_GET_SUCCESS,
});

export const loginGetFailure = (errorMessage: string): userDataActionType => ({
  errorMessage,
  type: clientsOpinionsConstants.LOG_IN_GET_FAILURE,
});

export const closeWarnBar = (): userDataActionType => ({
  type: clientsOpinionsConstants.CLOSE_WARN_BAR,
});

export const getUserData = () => async (dispatch: Dispatch) => {
  const accessToken = tokensHelper.getAccessToken();

  const res = await axiosInstance.get(`${API.url}/api/identity/User/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const userData = {
    email: res.data.email,
    firstName: res.data.firstName,
    surname: res.data.surname,
    province: res.data.province,
    city: res.data.city,
    phoneNumber: res.data.phoneNumber,
    companyName: res.data.companyName,
    ocupation: res.data.occupation,
  };
  dispatch(setUserData(userData));
  dispatch(setYourId(res.data.id));
};

export const logInUser = (email: string, password: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(loginGetRequest());

  const res = await axiosInstance.post(`${API.url}/api/identity/Auth/login`, {
    email,
    password,
  });

  const accessToken = res!.data.token.accessToken;
  const refreshToken = res!.data.token.refreshToken;
  const userRole = res!.data.roleNames[0];

  tokensHelper.setTokens(accessToken, refreshToken, userRole);

  await tokensHelper.setTokensRefreshingInterval();

  dispatch(loginGetSuccess());
  dispatch(setYourId(res!.data.userId));
};

export const registerUser = (values: registerValues) => async (
  dispatch: Dispatch,
) => {
  await axiosInstance.post(`${API.url}/api/identity/Auth/register`, values);
};

export const updateUserData = (userDataToChange: IUserDataToSent) => async (
  dispatch: Dispatch,
) => {
  const accessToken = tokensHelper.getAccessToken();
  await axiosInstance.put(`${API.url}/api/identity/User`, userDataToChange, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const changePassword = (
  password: string,
  repeatedPassword: string,
) => async () => {
  const accessToken = tokensHelper.getAccessToken();
  await axiosInstance.put(
    `${API.url}/api/identity/User/password`,
    {
      password,
      repeatedPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
};

export const resentNewPassword = (email: string) => async (
  dispatch: Dispatch,
) => {
  const res = await axiosInstance.post(
    `${API.url}/api/identity/Auth/password-reset?email=${email}`,
  );

  return res;
};

export const loginAdmin = (email: string, password: string) => async () => {
  const res = await axiosInstance.post(
    `${API.url}/api/identity/cms/Auth/login`,
    {
      email,
      password,
    },
  );

  const accessToken = res!.data.token.accessToken;
  const refreshToken = res!.data.token.refreshToken;
  const userRole = res!.data.roleNames[0];

  tokensHelper.setTokens(accessToken, refreshToken, userRole);

  await tokensHelper.setTokensRefreshingInterval();

  return res;
};

export const renewPassword = (
  userId: string,
  token: string,
  newPassword: string,
  newPasswordRepeat: string,
) => async (dispatch: Dispatch) => {
  await axiosInstance.post(
    `${API.url}/api/identity/Auth/validate-password-reset?UserId=${userId}&Token=${token}`,
    {
      newPassword,
      newPasswordRepeat,
    },
  );
};
