import { ClientConcernsConstants } from './ClientConcerns.constants';
import { clientOpinionsAcions } from './ClientConcerns.actions';
import {
  QuestionsTableItemProps,
  QuestionsTableItemStatus,
} from 'sections/AccountClientQuestionsTable/components/QuestionsTableItem/QuestionsTableItem';

export interface IconcernToEdit {
  answers: [];
  attachments: string[];
  selectedFactors: [];
  otherFactor: string[];
  description: string;
  id: string;
  readableId: string;
  createdAt: string;
  clientId: string;
  firstName: string;
  lastName: string;
  categoryName: string;
  title: string;
  offersCount: number;
  messagesCount: number;
  status: string;
}

export interface IClientConcernTable {
  concernTableData: QuestionsTableItemProps[];
  concernsType: QuestionsTableItemStatus;
  concernsTotalPages: number;
  concernToEditData: undefined | IconcernToEdit;
}

const INITIAL_STATE = {
  concernTableData: [] as QuestionsTableItemProps[],
  concernsType: QuestionsTableItemStatus.NONE,
  concernsTotalPages: 0,
  concernToEditData: undefined,
};

function ClientConcernDataReducer(
  state = INITIAL_STATE,
  action: clientOpinionsAcions,
) {
  switch (action.type) {
    case ClientConcernsConstants.SET_DEFAULT_TABLE_DATA:
      return {
        ...state,
        concernTableData: action.defaultConcernsData,
      };

    case ClientConcernsConstants.CLEAR_TABLE_DATA:
      return {
        ...state,
        concernTableData: [],
        concernsType: QuestionsTableItemStatus.NONE,
        concernsTotalPages: 0,
      };

    case ClientConcernsConstants.SET_CONCERNS_TYPE:
      return {
        ...state,
        concernsType: action.concernsType,
      };
    case ClientConcernsConstants.SET_CONCERNS_TOTAL_PAGES:
      return {
        ...state,
        concernsTotalPages: action.totalPages,
      };

    case ClientConcernsConstants.SET_CONCERN_TO_EDIT_DATA:
      return {
        ...state,
        concernToEditData: action.concernToEditData,
      };

    case ClientConcernsConstants.CLEAR_CONCERN_TO_EDIT_DATA:
      return {
        ...state,
        concernToEditData: undefined,
      };

    default:
      return state;
  }
}

export default ClientConcernDataReducer;
