import { userQuestionsDataConstants } from './clientQestion.constants';
import { userLoggedInActionType } from './clientQestion.actions';

export interface IQuestion {
  id: string;
  content: string;
}

export interface IClientQuestions {
  questions: IQuestion[];
  concernId: string;
}

const INITIAL_STATE = {
  questions: [] as IQuestion[],
  concernId: '' as string,
};

function userQuestionsReducer(
  state = INITIAL_STATE,
  action: userLoggedInActionType,
) {
  switch (action.type) {
    case userQuestionsDataConstants.ADD_QUESTIONS_DATA:
      return {
        ...state,
        questions: action.questions,
      };
    case userQuestionsDataConstants.CLEAR_QUESTIONS_DATA:
      return {
        ...state,
        questions: [],
      };
    case userQuestionsDataConstants.ADD_CONCERN_ID:
      return {
        ...state,
        concernId: action.id,
      };

    case userQuestionsDataConstants.CLEAR_CONCERN_ID:
      return {
        ...state,
        concernId: '',
      };

    default:
      return state;
  }
}

export default userQuestionsReducer;
