import { API } from 'api/API';
import { axiosInstance } from './axiosInstance';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export class tokensHelper {
  public static refreshingTokensIntervalIndex: number;

  public static getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  public static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  public static setTokens(
    accessToken: string,
    refreshToken: string,
    userRole?: string,
  ) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    if (userRole) {
      localStorage.setItem('userRole', userRole);
    }
  }

  public static async refreshTokens(functionToRecall?: (...rest: any) => void) {
    const currentAccessToken = tokensHelper.getAccessToken();
    const currentRefreshToken = tokensHelper.getRefreshToken();

    try {
      const res = await axiosInstance.post(
        `${API.url}/api/identity/Auth/tokens/refresh`,
        {
          accessToken: currentAccessToken,
          refreshToken: currentRefreshToken,
        },
      );

      const newAccessToken = res.data.accessToken;
      const newRefreshToken = res.data.refreshToken;

      tokensHelper.setTokens(newAccessToken, newRefreshToken);

      if (functionToRecall) {
        functionToRecall();
      }
    } catch (err) {
      if (err.message) {
        console.log(err.message);
      }
    }
  }

  public static async setTokensRefreshingInterval() {
    const token = this.getAccessToken();

    const decodedToken = jwtDecode<JwtPayload>(token!);

    const expirationDate = new Date(1000 * decodedToken.exp!).getTime();

    const now = new Date().getTime();
    const timeToExpire = expirationDate - now;

    const intervalToRefresh = timeToExpire - 132000;

    this.refreshingTokensIntervalIndex = window.setTimeout(async () => {
      await this.refreshTokens();
      this.setTokensRefreshingInterval();
    }, intervalToRefresh);

    return this.refreshingTokensIntervalIndex;
  }

  public static stopRefreshingTokens() {
    clearInterval(this.refreshingTokensIntervalIndex);
  }
}
