export enum ClientConcernsConstants {
  SET_DEFAULT_TABLE_DATA = 'SET_DEFAULT_TABLE_DATA',
  CLEAR_TABLE_DATA = 'CLEAR_TABLE_DATA',
  SET_CONCERNS_TYPE = 'SET_CONCERNS_TYPE',
  SET_CONCERNS_TOTAL_PAGES = 'SET_CONCERNS_TOTAL_PAGES',
  SET_CONCERN_TO_EDIT_DATA = 'SET_CONCERN_TO_EDIT_DATA',
  CLEAR_CONCERN_TO_EDIT_DATA = 'CLEAR_CONCERN_TO_EDIT_DATA',
  PUSH_CONCERN_ANSWERS_REQUEST = 'PUSH_CONCERN_ANSWERS_REQUEST',
  PUSH_CONCERN_ANSWERS_SUCCESS = 'PUSH_CONCERN_ANSWERS_SUCCESS',
  PUSH_CONCERN_ANSWERS_FAILURE = 'PUSH_CONCERN_ANSWERS_FAILURE',
  UPDATE_CONCERN_CATEGORIES_REQUEST = 'UPDATE_CONCERN_CATEGORIES_REQUEST',
  UPDATE_CONCERN_CATEGORIES_SUCCESS = 'UPDATE_CONCERN_CATEGORIES_SUCCESS',
  UPDATE_CONCERN_CATEGORIES_FAILURE = 'UPDATE_CONCERN_CATEGORIES_FAILURE',
}
