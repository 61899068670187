import * as navHeights from './navHeight';

const greenDark = {
  darker: '#0F6262',
  dark: '#5A787B',
  lighter: '#92A4A1',
};
const greenlight = {
  darker: '#DCE3E1',
  dark: '#E8ECEB',
  lighter: '#EFF3F3',
};

const gold = '#E59620';
const gray = '#CECECE';
const white = '#FFFFFF';

const theme = {
  colors: {
    greenDark,
    greenlight,
    gold,
    gray,
    white,
  },
  sizes: {
    pageHeader: {
      fontSizeMobile: 48,
      fontSizeDesktop: 60,
      lineHeightMobile: 48,
      lineHeightDesktop: 60,
    },
    lead: {
      fontSizeMobile: 20,
      fontSizeDesktop: 24,
      lineHeightMobile: 24,
      lineHeightDesktop: 24,
    },
    sectionTitle: {
      fontSizeMobile: 36,
      fontSizeDesktop: 48,
      lineHeightMobile: 48,
      lineHeightDesktop: 48,
    },
    sectionSubSectionTitle: {
      fontSizeMobile: 22,
      fontSizeDesktop: 30,
      lineHeightMobile: 30,
      lineHeightDesktop: 59,
    },
    description: {
      fontSizeMobile: 14,
      fontSizeDesktop: 16,
      lineHeightMobile: 24,
      lineHeightDesktop: 24,
    },
    btn: {
      fontSize: 14,
      lineHeight: 24,
    },
  },
  pageContentWrapper: {
    maxWidth: 1149,
    mobilePadding: 15,
  },
  navBar: {
    mobileHeight: navHeights.navMobileHeight,
    desktopHeight: navHeights.navDesktopHeight,
  },
  chat: {
    paddingSize: 27,
  },
};

export default theme;
