export enum messagesConstants {
  SET_YOUR_ID = 'SET_YOUR_ID',
  SET_INITIAL_CONVERSATION = 'SET_INITIAL_CONVERSATION',
  SET_ACTIVE_CHAT_DATA = 'SET_ACTIVE_CHAT_DATA',
  SET_YOUR_ACTIVE_CHAT_PARTNER_ID = 'SET_YOUR_ACTIVE_CHAT_PARTNER_ID',
  SET_LAST_MESSAGE = 'SET_LAST_MESSAGE',
  SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID',
  SET_NEW_CONVS_ORDER = 'SET_NEW_CONVS_ORDER',
  ADD_MESSAGE_TO_CHAT = 'ADD_MESSAGE_TO_CHAT',
  LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES',
  LOAD_MORE_CONVERSATIONS = 'LOAD_MORE_CONVERSATIONS',
  INCREASE_CONVERSATION_NEW_MESSAGES_COUNT = 'INCREASE_CONVERSATION_NEW_MESSAGES_COUNT',
  RESET_CONVERSATION_NEW_MESSAGES_COUNT = 'RESET_CONVERSATION_NEW_MESSAGES_COUNT',
  CLEAR_YOUR_ID = 'CLEAR_YOUR_ID',
  CLEAR_CHAT_DATA = 'CLEAR_CHAT_DATA',
  CLEAR_ACTIVE_CHAT_ID = 'CLEAR_ACTIVE_CHAT_ID',
  CLEAR_ACTIVE_CHAT_DATA = 'CLEAR_ACTIVE_CHAT_DATA',
  CLEAR_YOUR_ACTIVE_CHAT_PARTNER_ID = 'CLEAR_YOUR_ACTIVE_CHAT_PARTNER_ID',
  START_CHAT = 'START_CHAT',
  STOP_CHAT = 'STOP_CHAT',
  CHAT_CONNECTION_STATE = 'CHAT_CONNECTION_STATE',
}
