import { Suspense, lazy, memo } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute, { UserRoles } from 'components/PrivateRoute/PrivateRoute';

const HomepageClient = lazy(() => import('pages/HomepageClient'));
const HomepageLawyer = lazy(() => import('pages/lawyer/HomepageLawyer'));
const RegistrationLawyer = lazy(
  () => import('pages/lawyer/registration/RegistrationLawyer'),
);
const LoginPage = lazy(() => import('pages/login/LoginPage'));
const Forgot = lazy(() => import('pages/forgot/Forgot'));
const Ask = lazy(() => import('pages/ask/Ask'));
const AccountPage = lazy(() => import('pages/account/AccountPage'));
const AccountClientQuestions = lazy(
  () => import('pages/account/questions/AccountClientQuestions'),
);
const AccountLawyerOffers = lazy(
  () => import('pages/account/offers/AccountLawyerOffers'),
);
const RegistrationUser = lazy(
  () => import('pages/registration/RegistrationUser'),
);
const Logout = lazy(() => import('pages/logout/Logout'));
const OfferDetailsForLawyer = lazy(
  () => import('pages/account/lawyer/offer/[id]/OfferDetailsForLawyer'),
);
const ClientOfferDetails = lazy(
  () => import('pages/account/offer/[id]/ClientOfferDetails'),
);
const AccountMessages = lazy(
  () => import('pages/account/messages/AccountMessages'),
);
const EditConcern = lazy(
  () => import('pages/account/offer/[id]/edit/EditConcern'),
);
const AboutUs = lazy(() => import('pages/about/AboutUs'));
const PrintOffer = lazy(
  () => import('pages/account/offer/[id]/print/PrintOffer'),
);
const CMSPage = lazy(() => import('pages/cms/CMSPage'));
const NotFound = lazy(() => import('pages/404/404'));
const RenewPassword = lazy(() => import('pages/reset-password/resetPass'));

export interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  return (
    <Suspense fallback={null}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/" exact={true} component={HomepageClient} />
          <Route path="/about" exact={true} component={AboutUs} />
          <Route path="/lawyer" exact={true} component={HomepageLawyer} />
          <Route
            path="/lawyer/registration"
            exact={true}
            component={RegistrationLawyer}
          />
          <Route
            path="/registration"
            exact={true}
            component={RegistrationUser}
          />
          <Route path="/login" exact={true} component={LoginPage} />
          <Route path="/forgot" exact={true} component={Forgot} />

          <Route path="/ask" exact={true} component={Ask} />
          <PrivateRoute path="/account" exact>
            <AccountPage />
          </PrivateRoute>
          <PrivateRoute
            path="/account/questions"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.User}
          >
            <AccountClientQuestions />
          </PrivateRoute>
          <PrivateRoute
            path="/account/offers"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.Lawyer}
          >
            <AccountLawyerOffers />
          </PrivateRoute>
          <PrivateRoute
            path="/account/offer/:id"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.User}
          >
            <ClientOfferDetails />
          </PrivateRoute>
          <Route path="/logout" exact={true} component={Logout} />
          <PrivateRoute
            path="/account/lawyer/offer/:id"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.Lawyer}
          >
            <OfferDetailsForLawyer />
          </PrivateRoute>
          <PrivateRoute
            path="/account/lawyer/offer/:id/form"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.Lawyer}
          >
            <OfferDetailsForLawyer />
          </PrivateRoute>
          <PrivateRoute path="/account/messages" redirectPath="/" exact>
            <AccountMessages />
          </PrivateRoute>

          <PrivateRoute
            path="/account/offer/:id/edit"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.User}
          >
            <EditConcern />
          </PrivateRoute>

          <PrivateRoute
            path="/account/offer/:id/print"
            redirectPath="/"
            exact
            avaliableOnlyFor={UserRoles.User}
          >
            <PrintOffer />
          </PrivateRoute>

          <Route path="/cms" component={CMSPage} exact />

          <Route path="/reset-password" component={RenewPassword} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default memo(Router);
