import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import 'assets/fonts/fonts.css';

export default createGlobalStyle`
  ${normalize}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body,html{
     font-family: 'Lato', sans-serif;
     font-weight: 400;
  } 
  body.disableScroll {
    overflow: hidden;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 500000s ease-in-out 0s;
  }

  button {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`;
