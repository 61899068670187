import * as signalR from '@microsoft/signalr';
import { IMessage } from 'sections/CHAT/components/CHATMessangeChat/components/Message/Message';
import { API } from 'api/API';

export class chatService {
  public static connection = new signalR.HubConnectionBuilder()
    .withUrl(`${API.url}/hubs/chat`, {
      accessTokenFactory: () => `${localStorage.getItem('accessToken')}`,
      transport: signalR.HttpTransportType.LongPolling,
      // skipNegotiation: true,
    })
    // .configureLogging(signalR.LogLevel.Information)
    // .withAutomaticReconnect()
    .build();

  public static notifyAboutReadMessage(conversationId: string) {
    this.connection
      .invoke('ReadMessage', conversationId)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  }

  public static startConnection() {
    return this.connection.start();
  }

  public static stopConnection() {
    return this.connection.stop();
  }

  public static watchIfConnectionIsClossed(
    setLostConnection: (...rest: any) => any,
  ) {
    this.connection.onclose(async () => {
      try {
        await this.startConnection();
      } catch (err) {
        setLostConnection();
      }
    });
  }

  public static startReceivingMessages(
    onRecaivedFunction: (newReceivedMessage: IMessage) => void,
  ) {
    this.connection.on(
      'ReceiveMessage',
      (
        conversationId: string,
        concernTitle: string,
        senderId: string,
        firstname: string,
        surname: string,
        messageId: string,
        content: string,
        createdAt: string,
      ) => {
        const newReceivedMessage: IMessage = {
          conversationId,
          concernTitle,
          senderId,
          firstname,
          surname,
          id: messageId,
          content,
          createdAt,
        };

        // console.log(`
        // ${firstname} ${surname} o ${createdAt} napisał wiadomość: "${content}" do conwersacji o Id: ${conversationId}.`);

        onRecaivedFunction(newReceivedMessage);
      },
    );
  }

  public static off() {
    this.connection.off('ReceiveMessage');
  }

  public static scrollMessageChatToBottom() {
    const msgBox = document.querySelector('#chatMessagesWrapper');
    const msgBoxHeightWithMsgs = msgBox?.scrollHeight;
    const msgBoxHeight = msgBox?.clientHeight;

    msgBox!.scrollTop = msgBoxHeightWithMsgs! - msgBoxHeight!;
  }
}
