import { clientsOpinionsConstants } from './userData.constants';
import { userDataActionType } from './userData.actions';

export interface IlawyerItems {
  id: string;
  value: string;
}

export interface IUserData {
  email: string;
  firstName: string;
  surname?: string;
  province: string;
  city: string;
  phoneNumber: string;
  companyName?: string;
  ocupation?: string;
}

export enum LoggingStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface IUserDataState {
  data: IUserData;
  isUserDataAlreadyFetched: boolean;
  loggingStatus: LoggingStatus;
  errorMessage: null | string;
  registerLawyerInfo: string;
  resetPasswdInfo: string;
}

const INITIAL_STATE = {
  data: {} as IUserData,
  isUserDataAlreadyFetched: false,
  loggingStatus: LoggingStatus.INITIAL as LoggingStatus,
  errorMessage: null,
  registerLawyerInfo: '',
  resetPasswdInfo: '',
};

function userDataReducer(state = INITIAL_STATE, action: userDataActionType) {
  switch (action.type) {
    case clientsOpinionsConstants.SET_LAWYER_REGISTER_INFO:
      return {
        ...state,
        registerLawyerInfo:
          'Dziękujemy za rejestrację. Twoje konto niedługo zostanie aktywowane.',
      };

    case clientsOpinionsConstants.CLEAR_LAWYER_REGISTER_INFO:
      return {
        ...state,
        registerLawyerInfo: '',
      };

    case clientsOpinionsConstants.SET_RESET_PASSWD_INFO:
      return {
        ...state,
        resetPasswdInfo: 'Zmieniliśmy Twoje hasło!',
      };

    case clientsOpinionsConstants.CLEAR_RESET_PASSWD_INFO:
      return {
        ...state,
        resetPasswdInfo: '',
      };

    case clientsOpinionsConstants.SET_USER_DATA:
      return {
        ...state,
        data: action.userData,
        isUserDataAlreadyFetched: true,
      };

    case clientsOpinionsConstants.CLEAR_USER_DATA:
      return {
        ...state,
        data: {},
        isUserDataAlreadyFetched: false,
      };

    case clientsOpinionsConstants.LOG_IN_GET_REQUEST:
      return {
        ...state,
        loggingStatus: LoggingStatus.PENDING,
        errorMessage: null,
      };

    case clientsOpinionsConstants.LOG_IN_GET_SUCCESS:
      return {
        ...state,
        loggingStatus: LoggingStatus.SUCCESS,
        errorMessage: null,
      };

    case clientsOpinionsConstants.LOG_IN_GET_FAILURE:
      return {
        ...state,
        loggingStatus: LoggingStatus.FAILED,
        errorMessage: action.errorMessage,
      };

    case clientsOpinionsConstants.CLOSE_WARN_BAR:
      return {
        ...state,
        errorMessage: null,
      };

    default:
      return state;
  }
}

export default userDataReducer;
