import { ThemeProvider } from 'styled-components';
import theme from 'utils/theme';
import CreateGlobalStyle from './App.styled';
import Router from './Router';
import { tokensHelper } from 'helpers/tokensHelper';
import { useDispatch, useSelector } from 'react-redux';
import { loginGetSuccess } from 'store/userData/userData.actions';
import { memo, useCallback, useEffect } from 'react';
import { IMessage } from 'sections/CHAT/components/CHATMessangeChat/components/Message/Message';
import {
  increaseConvNewMessagesCount,
  setLastMessage,
  addNewMessage,
  setNewConvsOrder,
} from 'store/messages/messages.actions';
import { chatService } from 'services/chatService';
import { getUserData } from 'store/userData/userData.actions';
import { RootState } from 'store/rootReducer';
import {
  startChat,
  // setChatConnectionState,
} from 'store/messages/messages.actions';

const accessToken = tokensHelper.getAccessToken();

function App() {
  const dispatch = useDispatch();

  const handleTokens = useCallback(async () => {
    try {
      await tokensHelper.refreshTokens();
      await tokensHelper.setTokensRefreshingInterval();
      await dispatch(loginGetSuccess());
      await dispatch(getUserData());
      await dispatch(startChat());
    } catch (err) {}
  }, [dispatch]);

  useEffect(() => {
    if (!!accessToken) {
      handleTokens();
    }
  }, [dispatch, handleTokens]);

  const isChatStarted = useSelector(
    (state: RootState) => state.messages.isChatStarted,
  );

  const startReceivingMesages = useCallback(() => {
    const handleNewMessage = (newReceivedMessage: IMessage) => {
      dispatch(
        addNewMessage(newReceivedMessage, newReceivedMessage.conversationId!),
      );
      dispatch(setNewConvsOrder(newReceivedMessage));

      dispatch(
        increaseConvNewMessagesCount(
          newReceivedMessage.conversationId!,
          newReceivedMessage.senderId,
        ),
      );

      dispatch(
        setLastMessage(
          newReceivedMessage.conversationId!,
          newReceivedMessage.content,
        ),
      );
    };

    chatService.startReceivingMessages(handleNewMessage);

    const setConnectionLost = () => {
      // commented because on logout and login again isChatConnectionLost was set to true so it enabled warn about network err and prevented from sendimg messages even thou it technically could send messgs and had connection (and you could see new messages but send NOT by you)
      // dispatch(setChatConnectionState());
      tokensHelper.stopRefreshingTokens();
    };

    chatService.watchIfConnectionIsClossed(setConnectionLost);
  }, [dispatch]);

  const startChatConnection = useCallback(async () => {
    try {
      await chatService.startConnection();
      startReceivingMesages();
    } catch (err) {}
  }, [startReceivingMesages]);

  useEffect(() => {
    if (!!isChatStarted) {
      startChatConnection();
    }
  }, [startChatConnection, isChatStarted]);

  return (
    <ThemeProvider theme={theme}>
      <CreateGlobalStyle />
      <div className="App">
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default memo(App);
