import { offersToClientConcernConstants } from './OffersToClientConcern.constants';
import { userLoggedInActionType } from './OffersToClientConcern.actions';
import _ from 'lodash';

export interface ILawyerOffer {
  companyName: string;
  concernId: string;
  contactPersonId: string;
  createdAt: string;
  description: string;
  id: string;
  phoneNumber: string | number;
  status: string;
}

export enum lawyerOffersToClientConcernStatus {
  INITIAL = 'INITIAL',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface IlawyerOffersToClientConcernReducer {
  data: ILawyerOffer[];
  status: string;
}

const INITIAL_STATE = {
  data: [] as ILawyerOffer[],
  status: lawyerOffersToClientConcernStatus.INITIAL as lawyerOffersToClientConcernStatus,
};

function offersToClientConcernReducer(
  state = INITIAL_STATE,
  action: userLoggedInActionType,
) {
  switch (action.type) {
    case offersToClientConcernConstants.LAWYER_OFFERS_TO_CLIENT_CONCERN_REQUEST:
      return {
        ...state,
        status: lawyerOffersToClientConcernStatus.REQUEST,
      };
    case offersToClientConcernConstants.LAWYER_OFFERS_TO_CLIENT_CONCERN_SUCCESS:
      return {
        ...state,
        data: action.offers,
        status: lawyerOffersToClientConcernStatus.SUCCESS,
      };
    case offersToClientConcernConstants.REVERSE_SORT_ORDER:
      const newData = _.cloneDeep(state.data);
      return {
        ...state,
        data: newData.reverse(),
      };

    case offersToClientConcernConstants.LAWYER_OFFERS_TO_CLIENT_CONCERN_FAILURE:
      return {
        ...state,
        status: lawyerOffersToClientConcernStatus.FAILURE,
      };

    default:
      return state;
  }
}

export default offersToClientConcernReducer;
